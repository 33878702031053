import { useEffect, Suspense } from 'react';
import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../util/hooks';
import DelayedSpinner from './DelayedSpinner';
import { loginWithSessionId, selectUser } from '../reducers/auth';
import { userIsValidator } from '../util/utils';
import { fetchFilteredProjects } from '../reducers/filteredProjects';
import {
    selectPollingInterval,
    selectPollingActive,
    selectPollingTick,
    setTick,
    startPolling,
    stopPolling,
} from '../reducers/polling';
import {
    selectDefaultFiltered,
    setDefaultFilters,
    fetchProjectLimits,
    fetchPendingProjectCount,
} from '../reducers/projects';

const SiteWrapper = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const pollingTick = useAppSelector(selectPollingTick);
    const pollingInterval = useAppSelector(selectPollingInterval);
    const pollingActive = useAppSelector(selectPollingActive);
    const defaultFiltered = useAppSelector(selectDefaultFiltered);

    useEffect(() => {
        dispatch(loginWithSessionId());
    }, []); // eslint-disable-line

    useEffect(() => {
        if (user && !pollingActive) {
            dispatch(setTick());
            dispatch(startPolling());
            setInterval(() => dispatch(setTick()), pollingInterval);
        }
        if (user) {
            // Set default createdBy filter selection if user is creator
            if (!defaultFiltered) {
                dispatch(setDefaultFilters(user));
            }
            dispatch(fetchFilteredProjects());
            dispatch(fetchProjectLimits());
        }
        return () => {
            dispatch(stopPolling());
        };
    }, [user]);

    useEffect(() => {
        if (userIsValidator(user)) {
            dispatch(fetchPendingProjectCount());
        }
    }, [pollingTick]);

    return (
        <Box w="100%" h="100vh" data-testid="site-wrapper">
            <Suspense fallback={<DelayedSpinner />}>
                <Outlet />
            </Suspense>
        </Box>
    );
};

export default SiteWrapper;
