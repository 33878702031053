import { ThunkDispatch } from 'redux-thunk';

import { ActionReducerMapBuilder, AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as api from '../api';

import { ThunkType } from '../types/types';
import { UserType } from '../types/reducers/auth';
import { getErrorMessage, userIsAdmin, userIsValidator } from '../util/utils';

import type { RootState } from '../store';
import { ConfirmPasswordProps, LoginProps } from '../types/reducers/auth';

interface AuthState {
    user: UserType | null | false;
    loading: boolean;
    error: string | null;
    resetError: string | null;
    successMessage: string | null;
    resetSuccessMessage: string | null;
}

const initialState: AuthState = {
    user: null,
    loading: false,
    error: null,
    resetError: null,
    successMessage: null,
    resetSuccessMessage: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        startLogin: () => ({
            user: null,
            loading: true,
            error: null,
            successMessage: null,
            resetError: null,
            resetSuccessMessage: null,
        }),
        completeLogin: (state: AuthState, { payload }: PayloadAction<UserType>) => {
            state.user = payload;
            state.loading = false;
            state.error = null;
            state.successMessage = 'Login successful';
        },
        failLogin: (state: AuthState, { payload }: PayloadAction<string>) => {
            state.user = false;
            state.loading = false;
            state.error = payload;
            state.successMessage = 'Login failed';
        },
        attemptConfirmReset: () => ({
            user: initialState.user,
            loading: true,
            error: initialState.error,
            resetError: null,
            successMessage: null,
            resetSuccessMessage: null,
        }),
        completeConfirmReset: (state: AuthState) => {
            state.loading = false;
            state.error = null;
            state.resetError = null;
            state.resetSuccessMessage = 'Password reset successful. Login with your new password.';
        },
        failConfirmReset: (state: AuthState, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.resetError = payload;
            state.resetSuccessMessage = null;
        },
        startLogout: (state: AuthState) => {
            state.loading = true;
            state.error = null;
            state.successMessage = null;
            state.resetError = null;
            state.resetSuccessMessage = null;
        },
        completeLogout: (state: AuthState) => {
            state.user = false;
            state.loading = initialState.loading;
            state.error = initialState.error;
            state.resetError = initialState.resetError;
            state.successMessage = initialState.successMessage;
            state.resetError = initialState.resetError;
        },
        failLogout: (state: AuthState, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
        startIncrementInstructionViews: (state: AuthState) => {
            state.loading = true;
        },
        completeIncrementInstructionViews: (state: AuthState, { payload }: PayloadAction<number>) => {
            state.loading = false;
            if (state.user) {
                state.user.instruction_views = payload;
            }
        },
        failIncrementInstructionViews: (state: AuthState, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const {
    startLogin,
    completeLogin,
    failLogin,
    startIncrementInstructionViews,
    completeIncrementInstructionViews,
    failIncrementInstructionViews,
} = authSlice.actions;

export const loginWithEmail =
    ({ email, password }: LoginProps): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startLogin());
        try {
            const data = await api.postEmailLogin({ email, password });
            dispatch(completeLogin(data));
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Authorization error.');

            dispatch(failLogin(message));
        }
    };

export const { attemptConfirmReset, completeConfirmReset, failConfirmReset } = authSlice.actions;

export const resetPassword = async (email: string): Promise<void> => {
    try {
        const data = await api.postResetPassword(email);
        console.log(data);
    } catch (e: unknown) {
        const message: string = getErrorMessage(e, 'Unable to reset password.');
        console.log(message);
    }
};

export const confirmResetPassword =
    ({ uid, token, newPassword, newPasswordConfirmation }: ConfirmPasswordProps): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(attemptConfirmReset());

        if (!uid || !token) {
            return dispatch(failConfirmReset('An error prevented resetting your password'));
        }

        try {
            await api.postConfirmResetPassword({ uid, token, newPassword, newPasswordConfirmation });
            dispatch(completeConfirmReset());
            // eslint-disable-next-line
        } catch (e: any) {
            const errors = e.response?.data;
            // There can be multiple problems withe a password (i.e. it is too short,
            // it is too common) but for simplicity-sake just pass the first one to
            // the user. They can fix that problem and then deal with the next one.
            dispatch(failConfirmReset(errors['new_password2'][0]));
        }
    };

export const loginWithSessionId = (): ThunkType => async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(startLogin());
    try {
        const data = await api.getLoginWithSessionId();
        dispatch(completeLogin(data));
    } catch (e: unknown) {
        dispatch(failLogin(''));
    }
};

export const incrementInstructionViews =
    (): ThunkType => async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startIncrementInstructionViews());
        try {
            const data = await api.incrementInstructionViews();
            dispatch(completeIncrementInstructionViews(data));
        } catch (e: unknown) {
            dispatch(failIncrementInstructionViews(''));
        }
    };

export const { startLogout, completeLogout, failLogout } = authSlice.actions;
export const logoutUser = (): ThunkType => async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(startLogout());
    try {
        await api.postLogoutUser();
        dispatch(completeLogout());
    } catch (e: unknown) {
        const message: string = getErrorMessage(e, 'Logout error.');
        dispatch(failLogout(message));
    }
};

export const selectUser = ({ auth }: RootState) => auth.user;
export const selectUserLoading = ({ auth }: RootState) => auth.loading;
export const selectUserError = ({ auth }: RootState) => auth.error;
export const selectSuccessMessage = ({ auth }: RootState) => auth.successMessage;
export const selectResetError = ({ auth }: RootState) => auth.resetError;
export const selectResetSuccessMessage = ({ auth }: RootState) => auth.resetSuccessMessage;

export const createClearOnLogout = <T>(initialState: T) => {
    return (builder: ActionReducerMapBuilder<T>) => {
        builder
            .addCase(completeLogout, () => initialState)
            .addCase(failLogin, () => initialState)
            .addDefaultCase((state) => state);
    };
};

export const selectUserHasAdminAccess = (state: RootState) => {
    const user = selectUser(state);
    return userIsAdmin(user) || userIsValidator(user);
};

export default authSlice.reducer;
