import { lazy, Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { Paths } from './util/constants';

// Main Routes
const ErrorPage = lazy(() => import('./components/ErrorPage'));
const CreationWizard = lazy(() => import('./components/CreationFlow/CreationWizard'));
const ProjectEdit = lazy(() => import('./components/ProjectEdit'));
const ProjectDetailsPage = lazy(() => import('./components/ProjectDetailsPage'));
const ProjectsPage = lazy(() => import('./components/ProjectsPage'));
const ExplorePage = lazy(() => import('./components/Explore/ExplorePage'));

// Auth Routes
const ConfirmEmailPage = lazy(() => import('./components/ConfirmEmailPage'));
const ConfirmPasswordReset = lazy(() => import('./components/ConfirmPasswordReset'));
const EmailLogin = lazy(() => import('./components/EmailLogin'));
const OktaLogin = lazy(() => import('./components/OktaLogin'));
const PasswordReset = lazy(() => import('./components/PasswordReset'));

import ProtectedRoute from './components/ProtectedRoute';
import SiteWrapper from './components/SiteWrapper';

const router = createBrowserRouter([
    {
        path: Paths.home,
        element: <SiteWrapper />,
        errorElement: (
            <Suspense>
                <ErrorPage />
            </Suspense>
        ),
        children: [
            {
                path: Paths.home,
                element: (
                    <ProtectedRoute>
                        <ProjectsPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: Paths.emailLogin,
                element: <EmailLogin />,
            },
            {
                path: Paths.oktaLogin,
                element: <OktaLogin />,
            },
            {
                path: Paths.confirmEmail,
                element: <ConfirmEmailPage />,
            },
            {
                path: Paths.resetPassword,
                element: <PasswordReset />,
            },
            {
                path: Paths.confirmPasswordReset,
                element: <ConfirmPasswordReset />,
            },
            {
                path: Paths.newProject,
                element: (
                    <ProtectedRoute needsEditPermissions>
                        <CreationWizard />
                    </ProtectedRoute>
                ),
            },
            {
                path: Paths.editProject,
                element: (
                    <ProtectedRoute needsEditPermissions>
                        <CreationWizard />
                    </ProtectedRoute>
                ),
            },
            {
                path: Paths.projectEdit,
                element: (
                    <ProtectedRoute needsEditPermissions>
                        <ProjectEdit />
                    </ProtectedRoute>
                ),
            },
            {
                path: Paths.project,
                element: (
                    <ProtectedRoute>
                        <ProjectDetailsPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: Paths.explore,
                element: (
                    <ProtectedRoute>
                        <ExplorePage />
                    </ProtectedRoute>
                ),
            },
        ],
    },
]);

export default router;
