import { UserType } from './../types/reducers/auth';
import { FilterState, ProjectStatusType, SimpleUser, SortState } from './../types/reducers/projects';
import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';

import * as api from '../api';
import { RootState } from '../store';
import { DEFAULT_FILTERS, PROJECT_STATUS_DESCRIPTION, limitExceptionLabels } from '../util/constants';
import {
    filterLimitsToProjectType,
    getErrorMessage,
    isValidRangeOption,
    userIsAdmin,
    userIsValidator,
} from '../util/utils';
import { OptionType, ProjectTypeType, ThunkType } from '../types/types';
import { ProjectLimitDataType } from '../types/reducers/projects';
import { createClearOnLogout } from './auth';
import { queryBuilder } from '../util/queryBuilders';
import { forEach } from 'lodash';

type DefaultFilterKey =
    | 'townFilter'
    | 'createdByFilter'
    | 'districtFilter'
    | 'projectStatusFilter'
    | 'mapcallStatusFilter'
    | 'regulatoryStatusFilter'
    | 'categoryFilter'
    | 'projectTypeFilter'
    | 'baseRateFilter'
    | 'assetCategoryFilter'
    | 'assetTypeFilter'
    | 'foundationalFilingPeriodFilter'
    | 'highCostFactorsFilter'
    | 'materialFilter'
    | 'limitExceptionFilter';

type RangeFilterKey = 'impactScoreFilter' | 'lengthFilter' | 'estimateFilter' | 'diameterFilter';
type DateFilterKey = 'estInServiceFilter' | 'actualInServiceFilter';

type SearchFilterKey = 'searchFilter';

export type FilterKey = DefaultFilterKey | DateFilterKey | RangeFilterKey | SearchFilterKey;

export type FieldType =
    | 'created_by_email'
    | 'created_at'
    | 'district_name'
    | 'total_impact_score'
    | 'total_length'
    | 'status'
    | 'category'
    | 'name'
    | 'foundational_filing_period'
    | 'reg_status'
    | 'estimate'
    | 'town'
    | 'est_in_service'
    | 'actual_in_service'
    | 'mapcallStatus'
    | 'project_type'
    | 'base_rate'
    | 'asset_category'
    | 'asset_type'
    | 'prop_diameter'
    | 'prop_material'
    | 'high_cost_factors'
    | 'exception'
    | 'text_search';

const DefaultFilterKeyFieldMap: Record<DefaultFilterKey, FieldType> = {
    townFilter: 'town',
    createdByFilter: 'created_by_email',
    districtFilter: 'district_name',
    projectStatusFilter: 'status',
    mapcallStatusFilter: 'mapcallStatus',
    regulatoryStatusFilter: 'reg_status',
    categoryFilter: 'category',
    projectTypeFilter: 'project_type',
    baseRateFilter: 'base_rate',
    assetCategoryFilter: 'asset_category',
    assetTypeFilter: 'asset_type',
    foundationalFilingPeriodFilter: 'foundational_filing_period',
    highCostFactorsFilter: 'high_cost_factors',
    materialFilter: 'prop_material',
    limitExceptionFilter: 'exception',
};

const RangeFilterKeyFieldMap: Record<RangeFilterKey, FieldType> = {
    impactScoreFilter: 'total_impact_score',
    lengthFilter: 'total_length',
    estimateFilter: 'estimate',
    diameterFilter: 'prop_diameter',
};

const DateFilterKeyFieldMap: Record<DateFilterKey, FieldType> = {
    estInServiceFilter: 'est_in_service',
    actualInServiceFilter: 'actual_in_service',
};

const searchFilterKeyFieldMap: Record<SearchFilterKey, FieldType> = {
    searchFilter: 'text_search',
};

export const FilterKeyFieldMap: Record<FilterKey, FieldType> = {
    ...DefaultFilterKeyFieldMap,
    ...DateFilterKeyFieldMap,
    ...RangeFilterKeyFieldMap,
    ...searchFilterKeyFieldMap,
};

const DefaultFilterKeys = Object.keys(DefaultFilterKeyFieldMap) as DefaultFilterKey[];
const DateFilterKeys = Object.keys(DateFilterKeyFieldMap) as DateFilterKey[];
const RangeFilterKeys = Object.keys(RangeFilterKeyFieldMap) as RangeFilterKey[];
const searchFilterKeys = Object.keys(searchFilterKeyFieldMap) as SearchFilterKey[];

interface SetFilterPayloadType {
    selections: string[];
    filterKey: FilterKey;
}

interface SetCustomOptionPayload {
    option: OptionType;
    filterKey: FilterKey;
}

type SetToolbarHeightPayload = number;

interface ProjectsState {
    loading: boolean;
    error: string | null;
    sort: SortState;
    filters: Record<FilterKey, FilterState>;
    toolbarHeight: number | null;
    tabIndex: number;
    isMapHidden: boolean;
    createdBy: SimpleUser[];
    foundational_filing_periods: number[];
    limits: ProjectLimitDataType[];
    defaultFiltered: boolean;
    pendingProjectCount: number | null;
}

export const initialSortState: SortState = { field: '', order: 'asc' };

const defaultFilter: FilterState = {
    selections: [],
    filterString: '',
};

const defaultFilterWithCustomOption: FilterState = { ...defaultFilter, customOption: { value: '', label: '' } };

const initialState: ProjectsState = {
    loading: false,
    error: null,
    sort: initialSortState,
    toolbarHeight: null,
    tabIndex: 0,
    isMapHidden: false,
    filters: {
        ...(Object.fromEntries(RangeFilterKeys.map((filter) => [filter, defaultFilterWithCustomOption])) as Record<
            RangeFilterKey,
            FilterState
        >),
        ...(Object.fromEntries(DateFilterKeys.map((filter) => [filter, defaultFilterWithCustomOption])) as Record<
            DateFilterKey,
            FilterState
        >),
        ...(Object.fromEntries(DefaultFilterKeys.map((filter) => [filter, defaultFilter])) as Record<
            DefaultFilterKey,
            FilterState
        >),
        ...(Object.fromEntries(searchFilterKeys.map((filter) => [filter, defaultFilter])) as Record<
            SearchFilterKey,
            FilterState
        >),
    },
    createdBy: [],
    foundational_filing_periods: [],
    limits: [],
    defaultFiltered: false,
    pendingProjectCount: null,
};

const clearOnLogout = createClearOnLogout<ProjectsState>(initialState);

export const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        startFetchCreatedBy: (state: ProjectsState) => {
            state.createdBy = [];
            state.loading = true;
            state.error = null;
        },
        completeFetchCreatedBy: (state: ProjectsState, { payload }) => {
            state.createdBy = payload;
            state.loading = false;
            state.error = null;
        },
        failFetchCreatedBy: (state: ProjectsState, { payload }) => {
            state.createdBy = [];
            state.loading = false;
            state.error = payload.error;
        },
        setProjectsSort: (state: ProjectsState, { payload }: PayloadAction<SortState>) => {
            state.sort = payload;
        },
        startFetchFoundationalFilingPeriods: (state: ProjectsState) => {
            state.foundational_filing_periods = [];
            state.loading = true;
            state.error = null;
        },
        completeFetchFoundationalFilingPeriods: (state: ProjectsState, { payload }) => {
            state.foundational_filing_periods = payload;
            state.loading = false;
            state.error = null;
        },
        failFetchFoundationalFilingPeriods: (state: ProjectsState, { payload }) => {
            state.foundational_filing_periods = [];
            state.loading = false;
            state.error = payload.error;
        },
        startFetchLimits: (state: ProjectsState) => {
            state.limits = [];
            state.loading = true;
            state.error = null;
        },
        completeFetchLimits: (state: ProjectsState, { payload }) => {
            state.limits = payload;
            state.loading = false;
            state.error = null;
        },
        failFetchLimits: (state: ProjectsState, { payload }) => {
            state.limits = [];
            state.loading = false;
            state.error = payload;
        },
        startFetchPendingProjectCount: (state: ProjectsState) => {
            state.loading = true;
            state.error = null;
        },
        completeFetchPendingProjectCount: (state: ProjectsState, { payload }) => {
            state.pendingProjectCount = payload;
            state.loading = false;
            state.error = null;
        },
        failFetchPendingProjectCount: (state: ProjectsState, { payload }) => {
            state.pendingProjectCount = null;
            state.loading = false;
            state.error = payload;
        },
        setFilter: (state: ProjectsState, { payload }: { payload: SetFilterPayloadType }) => {
            const field = FilterKeyFieldMap[payload.filterKey];

            state.filters[payload.filterKey].selections = payload.selections;
            state.filters[payload.filterKey].filterString = queryBuilder(payload.selections, field);
            state.defaultFiltered = false;
        },
        setFilterCustomOption: (state: ProjectsState, { payload }: { payload: SetCustomOptionPayload }) => {
            state.filters[payload.filterKey].customOption = payload.option;
        },
        clearFilters: (state: ProjectsState) => {
            state.filters = initialState.filters;
            state.defaultFiltered = false;
        },
        clearSearchFilter: (state: ProjectsState) => {
            state.filters = { ...state.filters, searchFilter: defaultFilter };
            state.defaultFiltered = false;
        },
        updateToolbarHeight: (state: ProjectsState, { payload }: { payload: SetToolbarHeightPayload }) => {
            state.toolbarHeight = payload;
        },
        updateTabIndex: (state: ProjectsState, { payload }: { payload: number }) => {
            state.tabIndex = payload;
        },
        updateIsMapHidden: (state: ProjectsState, { payload }: { payload: boolean }) => {
            state.isMapHidden = payload;
        },
        setDefaultFiltered: (state: ProjectsState, { payload }: { payload: boolean }) => {
            state.defaultFiltered = payload;
        },
    },
    extraReducers: clearOnLogout,
});

export const {
    setProjectsSort,
    startFetchCreatedBy,
    completeFetchCreatedBy,
    failFetchCreatedBy,
    startFetchFoundationalFilingPeriods,
    completeFetchFoundationalFilingPeriods,
    failFetchFoundationalFilingPeriods,
    startFetchLimits,
    completeFetchLimits,
    failFetchLimits,
    startFetchPendingProjectCount,
    completeFetchPendingProjectCount,
    failFetchPendingProjectCount,
    setFilter,
    setFilterCustomOption,
    clearFilters,
    clearSearchFilter,
    updateToolbarHeight,
    updateTabIndex,
    updateIsMapHidden,
    setDefaultFiltered,
} = projectsSlice.actions;

export const fetchCreatedBy = (): ThunkType => async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(startFetchCreatedBy());
    try {
        const data = await api.getCreatedBy();
        dispatch(completeFetchCreatedBy(data));
    } catch (e: unknown) {
        const message: string = getErrorMessage(e, 'Error fetching users.');

        dispatch(failFetchCreatedBy(message));
    }
};

export const fetchProjectFoundationalFilingPeriods =
    (): ThunkType => async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startFetchFoundationalFilingPeriods());
        try {
            const data = await api.getProjectFoundationalFilingPeriods();
            dispatch(completeFetchFoundationalFilingPeriods(data));
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Error fetching project foundational filing periods.');

            dispatch(failFetchFoundationalFilingPeriods(message));
        }
    };

export const fetchProjectLimits = (): ThunkType => async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(startFetchLimits());
    try {
        const data = await api.getProjectLimits();
        dispatch(completeFetchLimits(data));
    } catch (e: unknown) {
        const message: string = getErrorMessage(e, 'Error fetching project limits.');

        dispatch(failFetchLimits(message));
    }
};

export const fetchPendingProjectCount =
    (): ThunkType => async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startFetchPendingProjectCount());
        try {
            const data = await api.getProjectCount('status=PENDING');
            dispatch(completeFetchPendingProjectCount(data['count']));
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Error fetching project limits.');

            dispatch(failFetchPendingProjectCount(message));
        }
    };

export const selectProjectsCreatedBy = (state: RootState) => state.projects.createdBy;
export const selectProjectsSort = (state: RootState) => state.projects.sort;
export const selectProjectsFilters = (state: RootState) => state.projects.filters;
export const selectProjectsFoundationalFilingPeriods = (state: RootState) => state.projects.foundational_filing_periods;
export const selectLimits = (state: RootState) => state.projects.limits;
export const selectGenericFilter = (filter: FilterKey) => (state: RootState) => state.projects.filters[filter];
export const selectToolbarHeight = (state: RootState) => state.projects.toolbarHeight;
export const selectTabIndex = (state: RootState) => state.projects.tabIndex;
export const selectIsMapHidden = (state: RootState) => state.projects.isMapHidden;
export const selectLoading = (state: RootState) => state.projects.loading;
export const selectDefaultFiltered = (state: RootState) => state.projects.defaultFiltered;
export const selectPendingProjectCount = (state: RootState) => state.projects.pendingProjectCount;

export const selectProjectLimits = (projectType: ProjectTypeType | undefined) => (state: RootState) => {
    const limits = state.projects.limits;
    return filterLimitsToProjectType(projectType, limits);
};

export const addArrayParams = (selections: string[], fieldName: string) => selections.map((item) => [fieldName, item]);

const getSelectionsWithCustomOption = (filter: FilterState): string[] =>
    filter.customOption?.selected && isValidRangeOption(filter.customOption)
        ? [...filter.selections, filter.customOption?.value]
        : filter.selections;

export const selectFilteredProjectsQuery = (
    state: RootState,
    include_fields?: 'some' | 'most',
    page_size?: number,
    page?: number,
    searchFromAutocomplete?: string,
) => {
    const { field: sortField, order: sortOrder } = state.projects.sort;
    const queries: string[][] = [];

    if (sortField) {
        queries.push(['sort', sortField]);
    }

    if (sortOrder) {
        queries.push(['order', sortOrder]);
    }

    if (page_size) {
        queries.push(['page_size', `${page_size}`]);
    }

    if (page) {
        queries.push(['page', `${page}`]);
    }

    if (include_fields) {
        queries.push(['include_fields', include_fields]);
    }

    const { filters } = state.projects;

    const searchFilter = searchFromAutocomplete
        ? [['text_search', searchFromAutocomplete]]
        : Object.entries(searchFilterKeyFieldMap)
              .map(([key, type]) => addArrayParams(filters[key as FilterKey].selections, type))
              .flat();

    const params = new URLSearchParams([
        ...queries,
        ...Object.entries(RangeFilterKeyFieldMap)
            .map(([key, type]) => addArrayParams(getSelectionsWithCustomOption(filters[key as FilterKey]), type))
            .flat(),
        ...Object.entries(DateFilterKeyFieldMap)
            .map(([key, type]) => addArrayParams(getSelectionsWithCustomOption(filters[key as FilterKey]), type))
            .flat(),
        ...Object.entries(DefaultFilterKeyFieldMap)
            .map(([key, type]) => addArrayParams(filters[key as FilterKey].selections, type))
            .flat(),
        ...searchFilter,
    ]);

    return `?${params}`;
};

export const createLimitExceptionsOptions = (limits: RootState['projects']['limits']) => {
    const totalsFirst = [
        ...limits.filter(({ fieldname }: ProjectLimitDataType) => fieldname.startsWith('total')),
        ...limits.filter(({ fieldname }: ProjectLimitDataType) => !fieldname.startsWith('total')),
    ];
    return totalsFirst.map(
        (l: ProjectLimitDataType): OptionType => ({
            label: `${limitExceptionLabels[l.fieldname]} out of range`,
            value: l.fieldname,
        }),
    );
};

export const createProjectStatusOptions = (statuses: ProjectStatusType[]) =>
    statuses.map((s: ProjectStatusType): OptionType => ({ label: PROJECT_STATUS_DESCRIPTION[s], value: s }));

const customOptionValidations: Record<FilterKey, (o: OptionType) => boolean> = {
    ...(Object.fromEntries(RangeFilterKeys.map((filter) => [filter, isValidRangeOption])) as Record<
        RangeFilterKey,
        (o: OptionType) => boolean
    >),
    ...(Object.fromEntries(
        Object.keys(DateFilterKeyFieldMap).map((filter) => [filter, (o: OptionType) => !!o]),
    ) as Record<DateFilterKey, (o: OptionType) => boolean>),
    ...(Object.fromEntries(DefaultFilterKeys.map((filter) => [filter, (o: OptionType) => !!o])) as Record<
        DefaultFilterKey,
        (o: OptionType) => boolean
    >),
    ...(Object.fromEntries(searchFilterKeys.map((filter) => [filter, (o: OptionType) => !!o])) as Record<
        SearchFilterKey,
        (o: OptionType) => boolean
    >),
};

export const selectAppliedFiltersCount = (state: RootState) => {
    const standardSelections = Object.values(state.projects.filters).reduce(
        (sum: number, f: FilterState) => sum + f.selections.length,
        0,
    );
    const customOptions = Object.keys(state.projects.filters).reduce((sum: number, key: string) => {
        const f = key as FilterKey;
        const customOption = state.projects.filters[f].customOption;
        if (!customOption || !customOption.selected) return sum;
        const isValid = customOptionValidations[f];
        if (isValid && isValid(customOption)) return sum + 1;
        return sum;
    }, 0);

    return standardSelections + customOptions;
};

export const setDefaultFilters =
    (user: UserType): ThunkType =>
    (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        if (!(userIsAdmin(user) || userIsValidator(user))) {
            const createdByFilterKey: FilterKey = 'createdByFilter';
            dispatch(
                setFilter({
                    filterKey: createdByFilterKey,
                    selections: [user.email],
                }),
            );
        }

        // Default filters apply to all users
        forEach(DEFAULT_FILTERS, (filter) => {
            dispatch(setFilter(filter));
        });
        dispatch(setDefaultFiltered(true));
    };

export default projectsSlice.reducer;
