import { combineReducers } from 'redux';

import authReducer from './auth';
import pollingReducer from './polling';
import districtsReducer from './districts';
import townsReducer from './towns';
import projectReducer from './project';
import mapDataReducer from './mapData';
import projectsReducer from './projects';
import filteredPaginatedProjectsReducer from './filteredPaginatedProjects';
import filteredProjectsReducer from './filteredProjects';
import clusterMapReducer from './clusterMap';
import dashboardReducer from './dashboard';
import nextLocationReducer from './nextLocation';
import downloadReducer from './download';
import searchSuggestionsReducer from './searchSuggestions';

const rootReducer = combineReducers({
    auth: authReducer,
    polling: pollingReducer,
    districts: districtsReducer,
    towns: townsReducer,
    currentProject: projectReducer,
    projects: projectsReducer,
    filteredProjects: filteredProjectsReducer,
    filteredPaginatedProjects: filteredPaginatedProjectsReducer,
    mapData: mapDataReducer,
    clusterMap: clusterMapReducer,
    dashboard: dashboardReducer,
    nextLocation: nextLocationReducer,
    download: downloadReducer,
    searchSuggestions: searchSuggestionsReducer,
});

export default rootReducer;
