import { ThunkDispatch } from 'redux-thunk';

import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as api from '../api';
import { SearchSuggestionsDataType, SearchSuggestionsState } from '../types/reducers/searchSuggestions';
import { ThunkType } from '../types/types';
import { getErrorMessage } from '../util/utils';

import type { RootState } from '../store';
import { createClearOnLogout } from './auth';
import { selectFilteredProjectsQuery } from './projects';

const initialState: SearchSuggestionsState = {
    data: { name: [], id: [] },
    loading: false,
    error: null,
};

const clearOnLogout = createClearOnLogout<SearchSuggestionsState>(initialState);

export const searchSuggestionsSlice = createSlice({
    name: 'searchSuggestions',
    initialState,
    reducers: {
        startFetchSearchSuggestions: (state: SearchSuggestionsState) => ({
            data: state.data,
            loading: true,
            error: null,
        }),
        completeFetchSearchSuggestions: (
            state: SearchSuggestionsState,
            { payload }: PayloadAction<SearchSuggestionsDataType>,
        ) => {
            state.data = payload;
            state.loading = false;
            state.error = null;
        },
        failFetchSearchSuggestions: (state: SearchSuggestionsState, { payload }: PayloadAction<string>) => {
            state.data = initialState.data;
            state.loading = false;
            state.error = payload;
        },
        clearSearchSuggestions: () => initialState,
    },
    extraReducers: clearOnLogout,
});

export const {
    startFetchSearchSuggestions: startFetchSearchSuggestions,
    completeFetchSearchSuggestions: completeFetchSearchSuggestions,
    failFetchSearchSuggestions: failFetchSearchSuggestions,
    clearSearchSuggestions: clearSearchSuggestions,
} = searchSuggestionsSlice.actions;

export const fetchSearchSuggestions =
    (searchBarInput: string): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState) => {
        dispatch(startFetchSearchSuggestions());
        try {
            const state = getState();
            const data = await api.getSearchSuggestions(
                selectFilteredProjectsQuery(state, undefined, undefined, undefined, searchBarInput),
            );
            dispatch(completeFetchSearchSuggestions(data));
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Failed to fetch search autocomplete suggestions.');

            dispatch(failFetchSearchSuggestions(message));
        }
    };

export const selectSearchSuggestions = ({ searchSuggestions }: RootState) => searchSuggestions.data;
export const selectsearchSuggestionsLoading = ({ searchSuggestions }: RootState) => searchSuggestions.loading;
export const selectsearchSuggestionsError = ({ searchSuggestions }: RootState) => searchSuggestions.error;

export default searchSuggestionsSlice.reducer;
